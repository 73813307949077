'use strict';

/**
 * library
 */
import 'slick-carousel';

/**
 * module
 */
import { scroll } from './_scroll';

/**
 * homeheader
 */
export const homeheader = (() => {
    const $carousel = $('.js-carousel-header');
    const $progress = $('.js-progress-bar');
    const $current = $('.js-progress-current');
    const $total = $('.js-progress-total');
    const speed = 5000;
    const $nav = $('.js-nav-fix');

    const init = () => {
        $progress.css('height', '0%').animate(
            {
                height: '100%'
            },
            speed,
            'linear'
        );

        $carousel
            .slick({
                arrows: true,
                dots: false,
                autoplay: true,
                autoplaySpeed: speed,
                centerMode: false,
                draggable: false,
                pauseOnFocus: false,
                pauseOnHover: false,
                prevArrow: '<img src="./assets/img/common/arw_top-prev.png" class="slide-arrow prev-arrow">',
                nextArrow: '<img src="./assets/img/common/arw_top-next.png" class="slide-arrow next-arrow">',
                responsive: [
                    {
                        breakpoint: 769,
                        settings: {
                            arrows: false,
                            autoplay: true,
                            pauseOnFocus: false,
                            pauseOnHover: false,
                            swipe: true,
                            draggable: true
                        }
                    }
                ]
            })
            .on('init', () => {
                $current.text(slick.currentSlide + 1);
                $total.text(slick.slideCount);
            })
            .on('beforeChange', () => {
                $progress.css('height', '0%').stop();
            })
            .on('afterChange', (event, slick, currentSlide, nextSlide) => {
                $current.text(currentSlide + 1);
                $progress.css('height', '0%').animate(
                    {
                        height: '100%'
                    },
                    speed,
                    'linear'
                );
            });
        $carousel.on('touchend', event => {
            $carousel.slick('play');
        });
        // ヘッダースライダー

        const $navOuter = $nav.outerHeight() * 1.4;
        const $navOffset = $nav.offset().top - $navOuter;

        $(window).scroll(function() {
            // console.log($thisOffset);
            // 特定の要素を超えた

            if ($(window).scrollTop() >= $navOffset) {
                // 遅延させてフェードイン
                $nav.addClass('is-fixed');
            } else {
                $nav.removeClass('is-fixed');
            }
        });
        // ヘッダーnavfix

        const $txtOver = $('.text_overflow');
        let count = 23;
        $txtOver.each((index, element) => {
            const $this = $(element);
            const $thisText = $this.text().trim();
            const textLength = $thisText.length;

            if (textLength > count) {
                const showText = $thisText.substring(0, count);
                const insertText = showText + '<span class="omit">…</span>';
                $this.html(insertText);
            }
        });
        // 文字数オーバーの省略
    };

    return {
        init: init
    };
})();
