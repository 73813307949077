'use strict';

/**
 * responsive
 */
export const responsive = (() => {
    const breakPoint = 769;
    const prefix = '@pc';

    const $img = $('.js-switch-image');
    const $tel = $('.js-switch-tel');

    /**
     * init
     */
    const init = () => {
        $img.each((index, element) => {
            const $this = $(element);
            switchImage($this);
        });

        $tel.each((index, element) => {
            const $this = $(element);
            switchTel($this);
        });

        $(window).on('resize', () => {
            setTimeout(() => {
                $img.each((index, element) => {
                    const $this = $(element);
                    switchImage($this);
                });
                $tel.each((index, element) => {
                    const $this = $(element);
                    switchTel($this);
                });
            }, 100);
        });
    };

    /**
     * breakPointで画像を切替
     * @param {jQuery} $img 切替画像のjQueryオブジェクト
     */
    const switchImage = $img => {
        let src = $img.attr('src');
        const w = parseInt($('body').width());

        if (w < breakPoint) {
            if (src.indexOf(prefix) >= 0) {
                $img.css('opacity', 0);
                src = $img.attr('src').replace(prefix + '.', '.');
            }
        } else {
            if (src.indexOf(prefix) < 0) {
                $img.css('opacity', 0);
                src = $img.attr('src').replace(/\.(?=(?:png|jpg|svg)$)/i, prefix + '.');
            }
        }

        $img.attr('src', src);
        $img.on('load', () => {
            $img.removeAttr('style');
        });
    };

    const switchTel = $tel => {
        const tel = $tel.text().replace(/-/g, '');
        const w = parseInt($('body').width());

        if (w >= breakPoint) {
            $tel.unwrap('a');
        } else {
            $tel.wrap('<a href="tel:' + tel + '"></a>');
        }
    };

    const isUpBreakpoint = () => {
        const w = parseInt($('body').width());

        if (w >= breakPoint) {
            return true;
        } else {
            return false;
        }
    };

    // public
    return {
        init: init,
        isUpBreakpoint: isUpBreakpoint
    };
})();
