'use strict';

/**
 * library
 */
import 'slick-carousel';

/**
 * module
 */
import { scroll } from './_scroll';

/**
 * instagram
 */
export const instagram = (() => {
    const $carousel = $('.js-carousel-instagram');
    const $show = $('.js-show-instagram');
    const $hide = $('.js-hide-instagram');

    const show = index => {
        $carousel.slick('slickGoTo', index, true);
        $('body').addClass('is-show-instagram');
        scroll.off();
    };

    const hide = () => {
        $('body').removeClass('is-show-instagram');
        scroll.on();
    };

    const init = () => {
        $carousel.slick({
            arrows: true,
            dots: true,
            draggable: false,
            prevArrow: '<img src="./assets/img/common/arw_prev.svg" class="slide-arrow prev-arrow">',
            nextArrow: '<img src="./assets/img/common/arw_next.svg" class="slide-arrow next-arrow">',
            responsive: [
                {
                    breakpoint: 769,
                    settings: {
                        arrows: false,
                        draggable: true
                    }
                }
            ]
        });

        $show.each((index, element) => {
            const $this = $(element);

            $this.on('click', event => {
                show(index);
            });
        });

        $hide.on('click', event => {
            hide();
        });
    };

    return {
        init: init
    };
})();
