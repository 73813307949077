'use strict';

/**
 * module
 */
import { scroll } from './_scroll';

/**
 * nav
 */
export const nav = (() => {
    const $toggle = $('.js-toggle-nav');
    const $hide = $('.js-hide-nav');

    const toggle = () => {
        $('body').toggleClass('is-show-nav');

        if ($('body').hasClass('is-show-nav')) {
            scroll.off();
        } else {
            scroll.on();
        }
    };

    const hide = () => {
        $('body').removeClass('is-show-nav');
        scroll.on();
    };

    const init = () => {
        $toggle.on('click', event => {
            toggle();
        });

        $hide.on('click', event => {
            hide();
        });
    };

    return {
        init: init
    };
})();
