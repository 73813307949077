'use strict';

/**
 * module
 */
import { scroll } from './_scroll';

/**
 * search
 */
export const search = (() => {
    const $toggle = $('.js-toggle-search');
    const $hide = $('.js-hide-search');

    const toggle = () => {
        $('body').toggleClass('is-show-search');

        if ($('body').hasClass('is-show-search')) {
            scroll.off();
        } else {
            scroll.on();
        }
    };

    const hide = () => {
        $('body').removeClass('is-show-search');
        scroll.on();
    };

    const init = () => {
        $toggle.on('click', event => {
            toggle();
        });

        $hide.on('click', event => {
            hide();
        });
    };

    return {
        init: init
    };
})();
