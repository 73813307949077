'use strict';

/**
 * library
 */
import imagesLoaded from 'imagesloaded';
imagesLoaded.makeJQueryPlugin($);

/**
 * module
 */
import { anchor } from './module/_anchor';
import { disabled } from './module/_disabled';
import { fitimg } from './module/_fitimg';
import { hover } from './module/_hover';
import { responsive } from './module/_responsive';
import { nav } from './module/_nav';
import { search } from './module/_search';
import { instagram } from './module/_instagram';
import { homeheader } from './module/_homeheader';

/**
 * main
 */
const main = (() => {
    /**
     * loaded
     */
    const loaded = () => {
        $('body').addClass('is-loaded');
    };

    /**
     * init
     */
    const init = () => {
        anchor.init();
        disabled.init();
        fitimg.init();
        hover.init();
        responsive.init();
        nav.init();
        search.init();
        instagram.init();

        if ($('.js-nav-fix').length > 0) {
            homeheader.init();
        }
    };

    // public
    return {
        init: init,
        loaded: loaded
    };
})();

$(() => {
    main.init();

    // $('body')
    //     .imagesLoaded()
    //     .done(instance => {
    //         main.loaded();
    //     })
    //     .fail(() => {
    //         main.loaded();
    //     });
});

$(window).on('load', () => {
    main.loaded();
});
