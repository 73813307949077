'use strict';

/**
 * hover
 */
export const hover = (() => {
    const $hover = $('.js-hover');

    /**
     * init
     */
    const init = () => {
        $hover.each((index, element) => {
            let is_touchstart = false;
            const $this = $(element);

            $this.on('mouseover touchstart', event => {
                if (event.type === 'touchstart') {
                    is_touchstart = true;
                    on($this);
                } else {
                    if (is_touchstart) {
                        // SPでmouseoverイベントもトリガーされる場合は、2重でonを呼ばない様にする
                        is_touchstart = false;
                    } else {
                        on($this);
                    }
                }
            });

            $this.on('mouseout touchend', event => {
                off($this);
            });
        });
    };

    /**
     * on
     */
    const on = $element => {
        $element.addClass('is-on');
    };

    /**
     * off
     */
    const off = $element => {
        $element.removeClass('is-on');
    };

    // public
    return {
        init: init
    };
})();
